body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-bottom: 4em; */
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

textarea,
input.text,
input[type='text'],
input[type='date'],
input[type='button'],
input[type='submit'],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type='date']:before { */
/*   content: attr(placeholder) !important; */
/*   color: #aaa; */
/*   margin-right: 0.5em; */
/* } */
/* input[type='date']:focus:before, */
/* input[type='date']:valid:before { */
/*   content: ''; */
/* } */

h1 {
  font-family: 'PT Serif';
  font-weight: 400;
  font-size: 1.5em;
}

h2 {
  font-family: 'Montserrat';
  font-size: 1.125em;
  /* font-family: 'PT Serif'; */
  /* font-weight: 400; */
}

h3 {
  font-family: 'Montserrat';
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 1px;
}

h4 {
  font-family: 'Montserrat';
  /* text-transform: uppercase; */
  margin-bottom: 0;
  letter-spacing: 2px;
}

h5 {
  font-size: 1.2em;
  font-family: 'Montserrat';
}

a {
  color: black;
  text-transform: uppercase;
}

.borderTop {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.avatar {
  font-size: 1rem;
}
.avatar img {
  object-fit: cover;
  object-position: center;
  max-width: none !important;
}
